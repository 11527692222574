import "antd/dist/reset.css";
import "./highcharts.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { MonitoringPlugin } from "@opendash/plugin-monitoring";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import { OpenServicePlugin } from "@opendash/plugin-openservice";
import { BDEPlugin } from "@opendash/plugin-bde";
import bdeConfig from "./config_bde";
import dayjs from "dayjs";

dayjs.locale(getCurrentLanguageSync());

init("opendash", async (factory) => {
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new TimeseriesPlugin());
  await factory.use(new MonitoringPlugin());

  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
    })
  );
  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );
  try {
    await factory.use(new BDEPlugin(bdeConfig));
  } catch (e) {
    console.error(e, "Could not load BDEPlugin");
  }
  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "openware.nx-web.de",
      filterValuesOlderThanMS: 15552000000,
    })
  );

  await factory.use(new HighchartsPlugin());
  await factory.use(new OpenServicePlugin());
  //Translation

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/en.json")
  );

  // Navigation:
  /*
  factory.registerStaticNavigationItem({
    id: "monitoring/dashboard",
    group: "monitoring",
    place: "frontpage",
    order: 1,
    label: "opendash:monitoring.label",
    icon: "fa:chart-line",
    color: "#4385c2",
    link: "/monitoring/dashboards",
    routeCondition: "**",
    activeCondition: "/",
  });

  factory.registerStaticNavigationGroup({
    label: "parse-admin:admin.label",
    order: 10,
    id: "admin/parse",
  });
  factory.registerStaticNavigationGroup({
    label: "openware:reporting.label",
    order: 30,
    id: "admin/reporting",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/item",
    group: "admin/parse",
    place: "frontpage",
    order: 100,
    label: "opendash:admin.label",
    icon: "fa:cogs",
    color: "#676767",
    link: "/admin/parse/_Role",
    routeCondition: "**",
    activeCondition: "/",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/role",
    group: "admin/parse",
    place: "sidebar",
    order: 10,
    label: "parse-custom:classes._Role.label_plural",
    icon: "fa:building",
    link: "/admin/parse/_Role",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_Role",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/user",
    group: "admin/parse",
    place: "sidebar",
    order: 20,
    label: "parse-custom:classes._User.label_plural",
    icon: "fa:users",
    link: "/admin/parse/_User",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_User",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/sources",
    group: "admin/parse",
    place: "sidebar",
    order: 30,
    label: "opendash:sources.label_plural",
    icon: "fa:database",
    link: "/admin/parse/OD3_Source",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_Source",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/presets",
    group: "admin/parse",
    place: "sidebar",
    order: 40,
    label: "parse-custom:classes.OD3_WidgetPreset.label",
    icon: "fa:clipboard",
    link: "/admin/parse/OD3_WidgetPreset",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_WidgetPreset",
  });
  */
  // ///////////////////////////////

  $parse.ui.setClassConfig({
    className: "OD3_WidgetPreset",
    titleFields: ["label"],
    displayFields: ["label", "tags", "description"],
    createFields: [],
    editFields: ["label", "tags", "description"],
  });

  $parse.ui.setDefaultView("OD3_WidgetPreset", {
    type: "table",
  });
  $parse.ui.setClassConfig({
    className: "OD3_Source",
    titleFields: ["name"],
    displayFields: ["name", "tag", "updatedAt", "parent"],
    createFields: ["name", "tag", "parent"],
    editFields: ["name", "parent"],
  });
  $parse.ui.setClassConfig({
    className: "OD3_Dashboard",
    titleFields: ["name"],
    displayFields: ["name", "source"],
    createFields: ["name"],
    editFields: ["name"],
  });
  $parse.ui.setDefaultView("OD3_Source", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        [$parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "OWPlcDevice",
    disableACLEditing: false,
    titleFields: ["name"],
    displayFields: [
      "name",
      "connectionString",
      "updatedAt",
      "enabled",
      "interval",
      "options",
      "extraOptions",
    ],
    createFields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
    editFields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
  });

  $parse.ui.setDefaultView("OWPlcDevice", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "OWPlcItem",
    disableACLEditing: false,
    titleFields: ["label"],
    displayFields: [
      "label",
      "updatedAt",
      "DeviceID",
      "source",
      "address",
      "type",
      "unit",
      "onChange",
      "enabled",
      "extraOptions",
      "OWSource",
    ],
    createFields: [
      "label",
      "DeviceID",
      "source",
      "address",
      "type",
      "unit",
      "enabled",
      "onChange",
      "extraOptions",
      "OWSource",
    ],
    editFields: [
      "label",
      "DeviceID",
      "source",
      "address",
      "type",
      "unit",
      "enabled",
      "onChange",
      "extraOptions",
      "OWSource",
    ],
    asSelect: {
      type: [
        ["Number", "Zahl"],
        ["String", "Zeichenkette"],
        ["Boolean", "Wahrheitswert"],
        ["Geo", "Geoinformation"],
        ["Object", "Datenstruktur"],
      ],
    },
  });

  $parse.ui.setDefaultView("OWPlcItem", {
    type: "canban",
    titleFields: ["label"],
    descriptionFields: ["address", "type"],
    groupByField: "DeviceID",
  });

  $parse.ui.setClassConfig({
    className: "BCM_Project",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
  });

  $parse.ui.setDefaultView("BCM_Project", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_TaskState",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
    orderField: "order",
  });

  $parse.ui.setDefaultView("BCM_TaskState", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_Task",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    createFields: [
      "project",
      "state",
      "label",
      "parent",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    editFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    orderField: "order",
  });
}).then((app) => {
  console.log("init open.DASH");
});
